import React from 'react';
import './BodyBlock.css'

const BodyBlock = ({clickMenu}) => {

    return (
        <div className={`BodyBlock_wrap`}>
            <div className={`titleBlockIframe noCopy`}>
                    <span >{clickMenu  && clickMenu[0]}</span>
            </div>
            <iframe src={`${clickMenu && clickMenu[1]}`} width="100%" height="100%" align="left"/>
        </div>
    );
};

export default BodyBlock;